import ListGroup from 'react-bootstrap/ListGroup';
import NavbarTwo from './components/NavbarTwo';

function Prefootercenter() {
  return (
    <ListGroup as="ul">
      <ListGroup.Item as="li">Services</ListGroup.Item>
      <ListGroup.Item as="li">Coures Offered</ListGroup.Item>
      <ListGroup.Item as="li">Batches</ListGroup.Item>
      <ListGroup.Item as="li">Career</ListGroup.Item>
      <ListGroup.Item as="li">Contact</ListGroup.Item>
    </ListGroup>
  );
}

export default Prefootercenter;