import React from 'react'
import reaccourse from '../images/react.jpeg';
import angularcourse from '../images/angular.jpeg';
import mernandmean from '../images/mernandmean.jpeg';
import uiux from '../images/uiandux.jpeg';
import Courselist from './Courselist';
import Course_03 from './Course_03';

const Business_Solutions = () => {
  return (
    <>
      <div className='courses'>
        <Course_03 />
      {/*   <br></br>
        <img src={mernandmean} alt='img1' />
        <img src={reaccourse} alt='img2' />
        <img src={angularcourse} alt='img3' />
        <img src={uiux} alt='img4' /> */}
      </div>
    </>
  )
}

export default Business_Solutions