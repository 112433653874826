import React from 'react';

function AngularCourseContent() {
  // Creating an array of headings
  const headings = [
    {
      title: "Angular Basics",
      subheadings: ['Overview of Angular, a TypeScript-based web application framework.',
        'Understanding the basic architecture of Angular applications.',
        'Organizing and structuring Angular applications using modules.',
        'Building components and creating templates in Angular.',
        'Implementing one-way and two-way data binding in Angular.',
        'Using built-in and custom directives in Angular templates.'
        
      ]
    },
    {
      title: "Angular Components",
      subheadings: ['Creating and organizing components in Angular applications.',
        'Understanding the lifecycle hooks of Angular components.',
        'Creating and using services to share data and logic across components.',
        'Implementing dependency injection for managing dependencies in Angular.',
        'Using built-in and custom pipes for transforming data in Angular.'
        
        ]
    },
    {
      title: "Angular Routing",
      subheadings: ['Implementing client-side routing in Angular applications.',
        'Passing and extracting parameters in Angular routes.',
        'Creating nested routes in Angular applications.',  
        'Implementing route guards for protecting routes in Angular.',
        'Loading modules lazily for optimizing Angular applications.'
        
        ]
    },
    {
      title: "Angular Forms",
      subheadings: ['Creating forms using the template-driven approach in Angular.',
        'Building forms with the reactive form approach in Angular.',
        'Implementing form validation in Angular applications.',
        'Creating dynamic forms based on data in Angular.'    
        
        ]
    },
    {
      title: "Angular Services",
      subheadings: ['Implementing and organizing services in Angular applications.',
        'Making HTTP requests and handling responses in Angular using HttpClient.',
        'Intercepting and modifying HTTP requests and responses in Angular.',
        'Using RxJS observables for handling asynchronous operations in Angular.'
        
        ]
    },
    {
      title: "Angular State Management",
      subheadings: ['Implementing state management in Angular applications with NgRx.',
        'Defining actions and reducers for state management with NgRx.',
        'Handling side effects in Angular applications using NgRx effects.',
        'Utilizing the NgRx store for managing application state in Angular.'
        
        ]
    },
    {
      title: "Angular Testing",
      subheadings: ['Writing unit tests for Angular components and services.',          
        'Performing integration tests for Angular applications.',
        'Configuring and using the TestBed for testing Angular components.',
        'Mocking services and dependencies in Angular tests.'
        
        ]
    },
    {
      title: "Angular Advanced Topics",
      subheadings: ['Adding animations and transitions to Angular applications.',      
        'Building Progressive Web Apps with Angular.',
        'Implementing server-side rendering with Angular Universal.',
        'Creating and using custom Angular schematics for project generation.',
        'Using Angular CLI commands for development and build processes.'
        
        ]
    },
   
  ];

  return (
    <div>
      
      {headings.map((heading, index) => (
        <div key={index}>
          <h2 style={{textAlign:'left'}}>{heading.title}</h2>
          <ul>
            {heading.subheadings.map((subheading, subIndex) => (
              <li key={subIndex} style={{textAlign:'left'}}>{subheading}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

export default AngularCourseContent;
