
import React from 'react'
import { Container, Typography } from '@mui/material'

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import ContactForm from './ContactForm';
import { CardMedia } from '@mui/material';
import contactimage from '../images/courses/contact.svg';
import CustomizedTables from './Timetable';
import Contactmore from './Contactmore';
import BasicGrid from './Home';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Industries = () => {
  return (
    <div>
      <Container>


      <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>

        <Grid xs={12} sm={12} md={7} log={7}>
          <Item>
          <Typography gutterBottom variant="h5" component="div">
      Batches Available Timings 
      </Typography>


      <CustomizedTables />
          </Item>

        <Item>
          <Contactmore />
        </Item>
 

        </Grid>

        <Grid xs={12} sm={12} md={5} log={5}>
          <Item>
          <Typography gutterBottom variant="h5" component="div">
      Reach Us
      </Typography>
          <ContactForm />
          </Item>


        </Grid>

        <Grid xs={12} sm={12} md={12} log={12}>

          </Grid>
      </Grid>
      </Box>

      <BasicGrid />
      </Container>
    </div>
  )
}

export default Industries