import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import about_IT_01 from '../images/courses/about_ITproSkillSet.svg'
import about_IT_02 from '../images/courses/about_ITproSkillSet_02.svg'
import about_IT_03 from '../images/courses/about_ITproSkillSet_03.svg'
import CardMedia from '@mui/material/CardMedia';
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import BasicGrid from './Home';

function About() {
    const serviceslist=[
        {
            title:'"Empower Your Tech Journey with Jeevan IT"',
            description:'Welcome to Jeevan IT, your gateway to mastering the dynamic realms of MERN, MEAN, React, and Angular development. At Jeevan IT, we are not just offering courses; we are crafting transformative learning experiences designed to elevate your expertise to unprecedented levels.'+<br></br>+'In todays fast-paced tech landscape, proficiency is paramount. Thats why we are committed to providing practical, hands-on training that immerses you in real-world scenarios. Whether you are a seasoned developer seeking to expand your skill set or a newcomer ready to embark on a career in web development, our courses are tailored to meet your needs.',
            url:about_IT_01
        },
        {
            title:'Our curriculum stands',
            description:'Our curriculum stands out with its emphasis on project-based learning. We understand that theoretical knowledge alone wonot cut it in the competitive job market. Thats why we have meticulously crafted our courses to incorporate industry-relevant projects, allowing you to apply your newfound skills in real-time settings. From conception to deployment, you will gain invaluable experience in the entire development lifecycle, preparing you for the challenges of the professional world.',
            url:about_IT_03
        },
        {
            title:'Our Expert instructors',
            description:'At Jeevan IT, our expert instructors bring a wealth of experience to the table. With their guidance, you will receive personalized mentorship and engage in interactive sessions and live demonstrations. This fosters an environment of collaboration and growth, ensuring that you not only understand the intricacies of MERN, MEAN, React, and Angular development but also excel in applying them effectively.'+<br></br>+'Whether your goal is to build dynamic web applications, advance your career prospects, or simply stay ahead in the ever-evolving tech industry, Jeevan IT is here to support you. Join us today and embark on a transformative journey towards mastery. Your future begins now.',
            url:about_IT_02
        },
        
    ]
  return (
    <Container >

    <Typography gutterBottom variant="h5" component="div">
        About our Organization
        </Typography>
    
        <Typography variant="body2" color="text.secondary">
        "ITproSkillSet" offers comprehensive training in modern IT technologies. Courses cover the MERN and MEAN stacks for full-stack web development, databases including relational and NoSQL, Python for versatile applications, and Java for enterprise-scale software development.
        </Typography>
    
    <Row xs={1} md={3} className="g-4">
      {serviceslist.map((slist, idx) => (
        <Col key={idx}>
          <Card>
          <CardMedia
              component="img"
              alt="green iguana"
              height="240"
              image={slist.url}
              style={{borderRadius:'5px 5px 0px 0px'}}
            />
           {/*  <Card.Img variant="top" src={slist.url} /> */}
            <Card.Body>
              <Card.Title>{slist.title}</Card.Title>
              <Card.Text>
               {slist.description}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>

    <BasicGrid />
    </Container>
  );
}

export default About;


