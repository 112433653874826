import Carousel from 'react-bootstrap/Carousel';
import banner1 from '../images/BannerMERN_MEAN.jpg';
import banner2 from '../images/BannerReact.jpg';
import banner3 from '../images/BannerAngular.jpg';
import banner4 from '../images/BannerUIUX.jpg';
import { useRef } from 'react';




function DarkVariantExample() {

  const bannerid=useRef(null);

  return (
    <div ref={bannerid} id='mainbanner'>
   
    <Carousel data-bs-theme="dark">
      <Carousel.Item>
        <img 
          className="d-block w-100"
          src={banner1}
          alt="First slide"
        />
        <Carousel.Caption>
         {/*  <h5>First slide label</h5>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={banner2}
          alt="Second slide"
        />
        <Carousel.Caption>
         {/*  <h5>Second slide label</h5>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={banner3}
          alt="Third slide"
        />
        <Carousel.Caption>
         {/*  <h5>Third slide label</h5>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={banner4}
          alt="Second slide"
        />
        <Carousel.Caption>
         {/*  <h5>Second slide label</h5>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>

     
    </div>
  );
}

export default DarkVariantExample;