import React from 'react'
import Aside from './Aside'
import Article from './Article'
import { Route, Routes } from 'react-router-dom'
import Api from './Api'
import About from './About'
import Service from './Service'
import Contact from './Contact'
import Career from './Career'
import Business_Solutions from './Business_Solutions'
import Industries from './Industries'
import Technologies from './Technologies'
import Portfolio from './Portfolio'
import Home from './Home'
import JavaScript from './courses/JavaScript'
import Reactcourse from './courses/React'
import Angularcourse from './courses/Angular'
import MernStackcourse from './courses/MernStack'
import MeanStackcourse from './courses/MeanStack'
import Databasecourse from './courses/Database'
import JavaFullstackcourse from './courses/JavaFullstack'
import PythonFullstackcourse from './courses/PythonFullstack'
import UiUxfullstack from './courses/UiUx'
/* import { Home } from '@mui/icons-material' */



const Contaner = () => {

  return (
    <div className='bodycontainer'>



      <article>

        <Routes>
          <Route path='/' element={<Home />}/>
          <Route path='/about' element={<About />}/>
          <Route path='/courses' element={<Business_Solutions />}/>
          <Route path='/services' element={<Service />}/>
          <Route path='/industries' element={<Industries />}/>
          <Route path='/technologies' element={<Technologies />}/>
          <Route path='/portfolio' element={<External />}/>
          <Route path='/contact' element={<Contact />}/>
          <Route path='/javascript' element={<JavaScript />}/>
          <Route path='/react' element={<Reactcourse />}/>
          <Route path='/angular' element={<Angularcourse />}/>
          <Route path='/mern-stack' element={<MernStackcourse />}/>
          <Route path='/mean-stack' element={<MeanStackcourse />}/>
          <Route path='/database' element={<Databasecourse />}/>
          <Route path='/javafullstack' element={<JavaFullstackcourse />}/>
          <Route path='/pythonfullstack' element={<PythonFullstackcourse />}/>
          <Route path='/ui-ux' element={<UiUxfullstack />}/>
        </Routes>
      </article>
    </div>
  )
}

function External() {
 
  window.open('https://blog.itproskillset.com','_blank');
  return null;
   /* window.location.href = 'https://blog.itproskillset.com/';  */
}

export default Contaner