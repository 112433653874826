import React from 'react';

function UiUxCourseContent() {
  // Creating an array of headings
  const headings = [
    {
      title: "HTML Introduction",
      subheadings: ['Introduction to Hypertext Markup Language (HTML)',
        'Fundamental concepts of HTML',
        'Basic principles of web design'
             ]
    },
    {
      title: "HTML Basics",
      subheadings: ['Creating paragraphs in HTML',
        'Usage of various heading tags in HTML',
        'Adding comments to HTML code',
        'Creating tables in HTML',
        'Overview of HTML lists',
        'Creating unordered lists in HTML',
        'Creating ordered lists in HTML',
        'Defining description lists in HTML',
        'Inserting line breaks in HTML',
        'Preserving whitespace with the pre tag',
        'Adding horizontal lines in HTML'
        
        ]
    },
    {
      title: "HTML Inline",
      subheadings: ['Understanding block and inline elements in HTML',
        'Creating hyperlinks in HTML',
        'Embedding images in HTML',
        'Applying bold formatting in HTML',
        'Applying italic formatting in HTML',
        'Using superscript and subscript in HTML',
        'General text formatting in HTML'
        
        ]
    },
    {
      title: "HTML Head",
      subheadings: ['Understanding the head section in HTML',
        'Defining the title of an HTML document',
        'Applying styles to HTML elements',
        'Configuring metadata in HTML',
        'Adding a favicon to a webpage'
        
        ]
    },
    {
      title: "HTML Form",
      subheadings: ['Creating forms in HTML',
        'Common form elements in HTML',
        'Input elements in HTML forms',
        'Specifying form submission actions in HTML'
        
        ]
    },
    {
      title: "Semantic HTML",
      subheadings: ['Using semantic HTML for improved document structure',
        'Dividing content using the div tag',
        'Defining content aside from the main content',
        'Structuring content with the section tag',
        'Adding footer information to HTML documents',
        'Identifying the main content section in HTML',
        'Incorporating figures and captions in HTML',
        'Ensuring accessibility in HTML documents'
        
        ]
    },
    {
      title: "HTML,CSS & JavaScript",
      subheadings: ['Using classes to style HTML elements',
        'Assigning identifiers to HTML elements',
        'Structuring the layout of HTML documents',
        'Creating responsive web designs with HTML',
        'Integrating JavaScript with HTML'
        
        ]
    },
    {
      title: "HTML Graphics & Media",
      subheadings: ['Embedding videos in HTML documents',
        'Including audio elements in HTML',
        'Using Scalable Vector Graphics in HTML',
        'Drawing graphics using the HTML canvas element'
        
        ]
    },
    {
      title: "HTML Miscellaneous",
      subheadings: ['Embedding external content using iframes',
        'Working with HTML character entities',
        'Quoting text in HTML',
        'Understanding file paths in HTML',
        'Adding emojis to HTML content',
        'Using symbols in HTML',
        'Utilizing the non-breaking space in HTML'
        
        ]
    }

  ];

  return (
    <div>
      
      {headings.map((heading, index) => (
        <div key={index}>
          <h2 style={{textAlign:'left'}}>{heading.title}</h2>
          <ul>
            {heading.subheadings.map((subheading, subIndex) => (
              <li key={subIndex} style={{textAlign:'left'}}>{subheading}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

export default UiUxCourseContent;
