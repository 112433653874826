import Table from 'react-bootstrap/Table';

function ResponsiveExample() {
  const headerfields=[
    'Timings',
    '1st Batch',
    '2nd Batch',
    '3rd Batch',
    '4th Batch'

  ]
  const timings=[
    {id:'Morning'},
    {m1:'6 - 7 am'},
    {m2:'7 - 8 am'},
    {m2:'8 - 9 am'},
    {m2:'9 - 10 am'}
  ]

  const afternoon=[
    {id:'After Noon'},
    {m1:' - '},
    {m2:' - '},
    {m2:' - '},
    {m2:' - '}
  ]

  const Evening=[
    {id:'Evening'},
    {m1:'5 - 6 pm'},
    {m2:'6 - 7 pm'},
    {m2:'7 - 8 pm'},
    {m2:'8 - 9 pm'}
  ]

  const weekend=[
    {id:'Weekend'},
    {m1:'6 - 8 am'},
    {m2:'8 - 10 am'},
    {m2:'5 - 7 pm'},
    {m2:'7 - 9 pm'}
  ]

  return (
    <Table responsive>
      <thead>
        <tr>
          {headerfields.map((fields, index) => (
            <th key={index}>
              {fields}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
      <tr>
          {timings.map((time, index) => (
            <th key={index}>
              {time.id}
              {time.m1}
              {time.m2}
              {time.m3}
              {time.m4}
            </th>
          ))}
        </tr>

        <tr>
          {afternoon.map((time, index) => (
            <th key={index}>
              {time.id}
              {time.m1}
              {time.m2}
              {time.m3}
              {time.m4}
            </th>
          ))}
        </tr>

        <tr>
          {Evening.map((time, index) => (
            <th key={index}>
              {time.id}
              {time.m1}
              {time.m2}
              {time.m3}
              {time.m4}
            </th>
          ))}
        </tr>

        <tr>
          {weekend.map((time, index) => (
            <th key={index}>
              {time.id}
              {time.m1}
              {time.m2}
              {time.m3}
              {time.m4}
            </th>
          ))}
        </tr>


      </tbody>
    </Table>
  );
}

export default ResponsiveExample;