
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Container } from '@mui/material';
import ReactCourseContent from './ReactCourseContent';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import ContactForm from '../ContactForm';
import { styled } from '@mui/material/styles';
import BasicGrid from '../Home';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


export default function Reactcourse() {

  let linebreak=<br />

  const coursedetails=[
    {
      title:'React Course Content',
      description:<ReactCourseContent />,
  },
  {
    title:'Assignments',
    description:<ReactCourseContent />,
},
  {
    title:'Real Time Projects',
    description:<ReactCourseContent />,
},
{
  title:'Personal Branding',
  description:<ReactCourseContent />,
},
{
title:'LinkedIn Tips and Tricks',
description:<ReactCourseContent />,
},
{
title:'Resume Building',
description:<ReactCourseContent />,
},

  ]

  return (
    <div>
       <Container>
      

       <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>

      <Grid xs={12} sm={12} md={8} log={8}>
        <Item>
        <Accordion defaultExpanded>
        
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
         
          <Typography>React with TypeScript</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           3 Months Duration
          </Typography>
        </AccordionDetails>
        
      </Accordion>
      {coursedetails.map((cdlist, idx) => ( 
     <Accordion>
     <AccordionSummary
       expandIcon={<ExpandMoreIcon />}
       aria-controls="panel2-content"
       id="panel2-header"
     >
       <Typography>{cdlist.title}</Typography>
     </AccordionSummary>
     <AccordionDetails>
       <Typography>
       {cdlist.description}
       </Typography>
     </AccordionDetails>
   </Accordion>
      

       ))}
       </Item>
        </Grid>


        <Grid xs={12} sm={12} md={4} log={4}>
        <Item>
        <Typography gutterBottom variant="h5" component="div" style={{margin:'20px 0px'}}>
        More about Courses
        </Typography>
 
       <ContactForm />
        </Item>
        </Grid>

        </Grid>
        </Box>

       <BasicGrid />
       </Container>
    </div>
  );
}

