import React from 'react'
import Slides from './Slides'

const Bannerarea = () => {

  return (
    <>

      <Slides  />

    </>
  )
}

export default Bannerarea