import React from 'react';

function JavaScriptContent() {
  // Creating an array of headings
  const headings = [
    {
      title: "JavaScript Introduction",
      subheadings: ["Introduction to the basics of JavaScript.",
      'Understanding JavaScript variables and constants.',
      'Outputting information to the console using console.log().',
      'Overview of JavaScript data types.',
      'Explanation of JavaScript operators.',
      'Proper usage of comments in JavaScript.',
      'Converting between different data types in JavaScript.'
      ]
    },
    {
      title: "JavaScript Control Flow",
      subheadings: ['Using comparison and logical operators in control flow.',
        'Conditional statements with if...else.',
        'Iterating through code using for loops.',
        'Implementation of while loops in JavaScript.',
        'Using the break statement in loops.',
        'The continue statement and its application.',
        'Using switch statements for multi-case scenarios.'
        ]
    },
    {
      title: "JavaScript Functions",
      subheadings: ['Creating functions and working with expressions.',
        'Understanding variable scope in JavaScript functions.',
        'Hoisting and its impact on variable declarations.',
        'Recursive functions and their implementation.'
        ]
    },
    {
      title: "JavaScript objects.",
      subheadings: ['Introduction to JavaScript objects.',
        'Working with methods in JavaScript objects.',
        'Creating constructor functions in JavaScript.',
        'Implementing getters and setters in objects.',
        'Understanding the prototype chain in JavaScript.'
        ]
    },
    {
      title: "JavaScript Types Array",
      subheadings: ['Basics of working with arrays in JavaScript.',
        'Creating and using multidimensional arrays.',
        'String manipulation and operations.',
        'Using for...in loops with arrays and objects.',
        'Handling numeric values in JavaScript.',
        'Introduction to symbols in JavaScript.'
        ]
    },
    {
      title: "JavaScript Exceptions & Modules",
      subheadings: ['Handling exceptions using try...catch...finally.',
        'Manually throwing exceptions in JavaScript.',
        'Introduction to modular programming in JavaScript.'
        ]
    },
    {
      title: "JavaScript ES6",
      subheadings: ['Overview of ECMAScript 6 (ES6) features.',
        'Writing concise arrow functions in ES6.',
        'Using default parameters in function declarations.',
        'Creating dynamic strings with template literals.',
        'Spreading elements using the spread operator.',
        'Understanding and using the Map object.',
        'Working with unique values using the Set object.',
        'Destructuring assignment for arrays and objects.',
        'Object-oriented programming with classes in JavaScript.',
        'Inheriting properties and methods in JavaScript.',
        'Iterating over iterable objects using for...of.',
        'Creating and using proxies in JavaScript.'
        ]
    },
    {
      title: "JavaScript Asynchronous",
      subheadings: ['Delaying execution with setTimeout().',
        'Understanding and using callbacks.',
        'Managing asynchronous operations with promises.',
        'Simplifying asynchronous code with async/await.',
        'Repeatedly executing code with setInterval().'
        ]
    },
    {
      title: "JavaScript Miscellaneous",
      subheadings: ['Working with JSON (JavaScript Object Notation).',
        'Manipulating dates and times in JavaScript.',
        'Understanding closures in JavaScript.',
        "The 'this' keyword in different contexts.",
        "Enforcing strict mode in JavaScript.",
        "Working with iterators and iterables in JavaScript.",
        "Creating and using generators in JavaScript.",
        "Pattern matching and manipulation with regular expressions.",
        "Debugging JavaScript code in web browsers.",
        "Applications and use cases of JavaScript in web development and beyond."
        ]
    }

  ];

  return (
    <div>
      
      {headings.map((heading, index) => (
        <div key={index}>
          <h2 style={{textAlign:'left'}}>{heading.title}</h2>
          <ul>
            {heading.subheadings.map((subheading, subIndex) => (
              <li key={subIndex} style={{textAlign:'left'}}>{subheading}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

export default JavaScriptContent;
