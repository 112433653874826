import React from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from 'react-router-dom'
import reactlogo from "../ITproSkillSet_Logo_2.svg";
import jitlogo from '../jeevanit_logo.svg';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SchoolIcon from '@mui/icons-material/School';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PublicIcon from '@mui/icons-material/Public';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import LanguageIcon from '@mui/icons-material/Language';
import WebOutlinedIcon from '@mui/icons-material/WebOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';

import NavDropdown from 'react-bootstrap/NavDropdown';
import { Height } from '@mui/icons-material';
import HeaderContent from './HeaderContent';

function NavbarTwo() {

    const navigate=useNavigate();

    const isActive=true
    const activeLink ={
        color:'red',
        background:'yellow'
        
    };
    const normalLink = "";
  

  const menuItems=[
    {label:'ABOUTS', path:'/about',iconssrc:<ApartmentOutlinedIcon />},
    {label:'SERVICES', path:'/services',iconssrc:<ViewListOutlinedIcon />},
    {label:'COURSES OFFERED', path:'/courses',iconssrc:<AssignmentIcon />},
    {label:'BATCHES', path:'/industries',iconssrc:<AccessTimeIcon />},
    {label:'CAREER', path:'/technologies',iconssrc:<EmojiTransportationIcon />},
    {label:'BLOG', path:'/portfolio',iconssrc:<WebOutlinedIcon />},
    {label:'CONTACT', path:'/contact',iconssrc:<SupportAgentIcon />},
    
  ];

  const handlenavigation=(path)=>{
    
    const banner=document.getElementById('banner');
    const mainbanner=document.getElementById('mainbanner');
    const courselist=document.getElementById('courselist');
    navigate(path);
    banner.style.display='none';
    mainbanner.style.display='none';
    courselist.style.display='none';
   
  }
  const showbanner=()=>{
    const banner=document.getElementById('banner');
    const mainbanner=document.getElementById('mainbanner');
    const courselist=document.getElementById('courselist');
    navigate('/');
    banner.style.display='block';
    mainbanner.style.display='block';
    courselist.style.display='block';
  }

  return (
    <Navbar expand="lg" className="bg-body-tertiary" style={{position:'sticky',top:'0px',left:'0px',zIndex:'1000',boxShadow:'0px 0px 3px #000',fontSize:'12px'}}>
      <Container fluid>
        <Navbar.Brand>
        {<div className='logo' onClick={showbanner} 
          style={{cursor:'pointer'}}>
        <img src={jitlogo} alt='itproskillset' />
        </div>}
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '200px' }}
            navbarScroll
          >
           
            {menuItems.map((item,index)=>(
         <div key={index} onClick={()=>handlenavigation(item.path)} style={{padding:'0px 15px',cursor:'pointer'}} className={({ isActive }) => (isActive ? activeLink.color : normalLink)}>
          {item.iconssrc} 
          {item.label}
        </div>
      
      ))}
    
      </Nav>
            

          <HeaderContent />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarTwo;


