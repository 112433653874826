import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Prefooterleft from './Prefooterleft';
import Prefootercenter from './Prefootercenter';
import Prefooterright from './Prefooterright';

const Prefooter = () => {
  return (
    <div>
        <div className='prefooter'>
           <Container>
            <Row style={{display:'flex',alignItems:'start',justifyContent:'center',paddingTop:'30px'}}>
              <Col xs={12} sm={12} md={4} lg={4} className='mt'>
                <Prefooterleft />
              </Col>

              <Col xs={12} sm={12} md={4} lg={4} className='mt'>
                <Prefootercenter className='mt' />
              </Col>
              
              <Col xs={12} sm={12} md={4} lg={4} className='mt'>
                <Prefooterright />
              </Col>
            </Row>
           </Container>
        </div>
    </div>
  )
}

export default Prefooter