import React from 'react'

const Footer = () => {
  return (
    <div className='footer'>
      copyright@2024 www.jeevanit.com
    </div>
  )
}

export default Footer