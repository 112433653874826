import React from 'react'
import TrainingMode2 from './TrainingMode2'

const Service = () => {
  return (
    <div>
  
<TrainingMode2 />
    </div>
  )
}

export default Service