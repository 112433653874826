
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Container } from '@mui/material';


export default function PythonFullstackcourse() {

  const coursedetails=[
    {
        title:'What is JavaFullStack',
        description:'JavaScript is a versatile language primarily for web development. It enables dynamic, interactive content on websites. Supporting both object-oriented and functional programming, it boasts broad browser compatibility and a vast library ecosystem.',
    },
    {
      title:'What is JavaFullStack',
      description:'JavaScript is a versatile language primarily for web development. It enables dynamic, interactive content on websites. Supporting both object-oriented and functional programming, it boasts broad browser compatibility and a vast library ecosystem.',
  },
  {
    title:'What is JavaFullStack',
    description:'JavaScript is a versatile language primarily for web development. It enables dynamic, interactive content on websites. Supporting both object-oriented and functional programming, it boasts broad browser compatibility and a vast library ecosystem.',
},
{
  title:'What is JavaFullStack',
  description:'JavaScript is a versatile language primarily for web development. It enables dynamic, interactive content on websites. Supporting both object-oriented and functional programming, it boasts broad browser compatibility and a vast library ecosystem.',
},
  ]

  return (
    <div>
       <Container>
      
      <Accordion defaultExpanded>
        
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
         
          <Typography>Java FullStack Coures Content</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           4 Months Duration
          </Typography>
        </AccordionDetails>
        
      </Accordion>
      {coursedetails.map((cdlist, idx) => ( 
     <Accordion>
     <AccordionSummary
       expandIcon={<ExpandMoreIcon />}
       aria-controls="panel2-content"
       id="panel2-header"
     >
       <Typography>{cdlist.title}</Typography>
     </AccordionSummary>
     <AccordionDetails>
       <Typography>
       {cdlist.description}
       </Typography>
     </AccordionDetails>
   </Accordion>

       ))}
       </Container>
    </div>
  );
}

