import { Container, Typography } from '@mui/material'
import React from 'react'

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import ContactForm from './ContactForm';
import { CardMedia } from '@mui/material';
import contactimage from '../images/courses/contact.svg';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Technologies = () => {
  return (
    <div>
      <Container>  
      <Typography gutterBottom variant="h5" component="div">
      Discover Exciting Career Opportunities with Jeevan IT
        </Typography>


      <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>

        <Grid xs={12} sm={12} md={7} log={7}>
          <Item>
          

    
        <Typography variant="body2" color="text.secondary">
        Embark on a journey of growth and innovation with Jeevan IT, a renowned name in education and technology. Join our dynamic team and become an integral part of a culture that promotes collaboration, creativity, and advancement. Explore the diverse range of roles we offer:
        </Typography>

        <Typography gutterBottom variant="h5" component="div">
        Faculty Members:
        </Typography>
    
        <Typography variant="body2" color="text.secondary">
        Inspire and educate the next generation by joining our esteemed faculty.
        </Typography>


        <Typography gutterBottom variant="h5" component="div">
        Course Counselors: 
        </Typography>
    
        <Typography variant="body2" color="text.secondary">
        Assist individuals in making informed decisions about their educational path.
        </Typography>

        <Typography gutterBottom variant="h5" component="div">
        Telecallers: 
        </Typography>
    
        <Typography variant="body2" color="text.secondary">
        Serve as the initial point of contact for prospective students interested in ITpro-SkillSet courses.
        </Typography>

        <Typography gutterBottom variant="h5" component="div">
        Digital Marketing Executives:
        </Typography>
    
        <Typography variant="body2" color="text.secondary">
        Drive our brand's online presence through strategic digital marketing initiatives.

        </Typography>

        <Typography variant="body2" color="text.secondary">
        our careers page to explore current openings and submit your application. Join us as we empower individuals to excel in their careers. We eagerly await the addition of passionate and dedicated professionals to our team!
        </Typography>

          </Item>
        </Grid>

        <Grid xs={12} sm={12} md={5} lg={5}>

   

          <Item> 
     

          <CardMedia
              component="img"
              alt="green iguana"
         
              image={contactimage}
              style={{borderRadius:'5px 5px 0px 0px'}}
            />
    

          <Typography gutterBottom variant="h5" component="div">
        Apply Now
        </Typography>

            <ContactForm />
            </Item>
        </Grid>


        </Grid>
        </Box>

      

    </Container>
 
    </div>
  )
}

export default Technologies