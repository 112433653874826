import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import PhotoCameraFrontIcon from '@mui/icons-material/PhotoCameraFront';

import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import PortraitIcon from '@mui/icons-material/Portrait';
import { Typography } from '@mui/material';


function Trainingmode() {
  return (
    <div id='banner'>
    <Container style={{minHeight:'350px',marginTop:'30px'}}>

    <Row>
        <Col xs ={12} sm={12} md={12} lg={12}>
  
        <Typography gutterBottom variant="h5" component="div">
          Training Methods
        </Typography>
        <Typography variant="body2" color="text.secondary">
        Explore diverse training approaches—online, offline, weekend, and corporate—tailored to various needs. From virtual classes to hands-on sessions, these methods empower learners with flexible and interactive skill development opportunities.
        </Typography>

        </Col>
    </Row>

      <Row style={{textAlign:'center'}}>
        <Col xs ={12} sm={12} md={3} lg={3} className='aliginicon modebgcolor'>
          <PhotoCameraFrontIcon className='iconsize' />
          
          <Typography gutterBottom variant="h5" component="div">
          Online Training
        </Typography>
        <Typography variant="body2" color="text.secondary">
        Virtual classes via platforms like Zoom, covering IT skills, accessible globally, flexible timing, interactive.
         </Typography>

        </Col>
        <Col xs ={12} sm={12} md={3} lg={3} className='aliginicon modebgcolor'>
          <PortraitIcon className='iconsize' />
          
          <Typography gutterBottom variant="h5" component="div">
          Offline Training
        </Typography>
        <Typography variant="body2" color="text.secondary">
        Physical classroom sessions, hands-on practice, local venues, instructor-led, suitable for face-to-face learning.
         </Typography>

        </Col>
        <Col xs ={12} sm={12} md={3} lg={3} className='aliginicon modebgcolor'>
          <CorporateFareIcon className='iconsize' />
                
          <Typography gutterBottom variant="h5" component="div">
          Corporate Training
        </Typography>
        <Typography variant="body2" color="text.secondary">
        Tailored programs for companies, on-site or virtual, customized content, team building, enhances workforce skills.
         </Typography>

        </Col>
        <Col xs ={12} sm={12} md={3} lg={3} className='aliginicon modebgcolor'>
          <InsertInvitationIcon className='iconsize' />
        
          <Typography gutterBottom variant="h5" component="div">
          Weekend Training
        </Typography>
        <Typography variant="body2" color="text.secondary">
        Condensed sessions on Saturdays and Sundays, intensive learning, ideal for working professionals, covers key topics.
         </Typography>

        </Col>
      </Row>
      
    </Container>
    </div>
  );
}

export default Trainingmode;