
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Container } from '@mui/material';
import JavaScriptContent from './JavaScriptContent';


export default function JavaScript() {

  const coursedetails=[
    {
        title:'JavaScript Course Content',
        description:<JavaScriptContent />,
    },
    {
      title:'Assignments',
      description:<JavaScriptContent />,
  },
    {
      title:'Real Time Projects',
      description:<JavaScriptContent />,
  },
  {
    title:'Personal Branding',
    description:<JavaScriptContent />,
},
{
  title:'LinkedIn Tips and Tricks',
  description:<JavaScriptContent />,
},
{
  title:'Resume Building',
  description:<JavaScriptContent />,
},

  ]

  return (
    <div>
       <Container>
      
      <Accordion defaultExpanded>
        
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
         
          <Typography>JavaScript Course and Duration</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           Course Duration : 2 Months
          </Typography>
        </AccordionDetails>
        
      </Accordion>
      {coursedetails.map((cdlist, idx) => ( 
     <Accordion>
     <AccordionSummary
       expandIcon={<ExpandMoreIcon />}
       aria-controls="panel2-content"
       id="panel2-header"
     >
       <Typography>{cdlist.title}</Typography>
     </AccordionSummary>
     <AccordionDetails>
       <Typography>
      {cdlist.description}
       </Typography>
     </AccordionDetails>
   </Accordion>

       ))}
       </Container>
    </div>
  );
}

