import React from 'react'

import Navbar from './components/Navbar'

import Contaner from './components/Contaner'
import Footer from './components/Footer'
import Prefooter from './Prefooter'
import Bannerarea from './components/Bannerarea'
import Trainingmode from './components/Trainingmode'
/* import Courselist from './components/Courselist' */
import NavbarTwo from './components/NavbarTwo'
import Course_03 from './components/Course_03'





const App = () => {

  return (
    <div>
      <NavbarTwo />
      <Bannerarea />
      <Trainingmode />
      <Course_03 />
      <Contaner  />
      <Prefooter />
      <Footer />
      
    </div>
  )
}

export default App