import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import { Phone } from '@mui/icons-material';

function ContactForm() {
  const { Formik } = formik;

  const schema = yup.object().shape({
    FirstName: yup.string().required(),
    LastName: yup.string().required(),
    EmailId: yup.string().required(),
    PhoneNumber: yup.string().required(),
    state: yup.string().required(),
    zip: yup.string().required(),
    Comment: yup.mixed().required(),
    terms: yup.bool().required().oneOf([true], 'terms must be accepted'),
  });

  return (
    <>
   
    <Formik
      validationSchema={schema}
      onSubmit={console.log}
      initialValues={{
        FirstName: '',
        LastName: '',
        EmailId: '',
        PhoneNumber: '',
        State: '',  
        Comment: null,
        terms: false,
      }}
    >
  
      {({ handleSubmit, handleChange, values, touched, errors }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group
              as={Col}
              md="4"
              controlId="validationFormik101"
              className="position-relative"
            >
              <Form.Label>FirstName</Form.Label>
              <Form.Control
                type="text"
                name="FirstName"
                
                value={values.FirstName}
                onChange={handleChange}
                isValid={touched.FirstName && !errors.FirstName}
              />
              <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              as={Col}
              md="4"
              controlId="validationFormik102"
              className="position-relative"
            >
              <Form.Label>LastName</Form.Label>
              <Form.Control
                type="text"
                name="LastName"
                
                value={values.LastName}
                onChange={handleChange}
                isValid={touched.LastName && !errors.LastName}
              />

              <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationFormikUsername2">
              <Form.Label>Email Id</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                <Form.Control
                  type="text"
                  /* placeholder="Email Id" */
                  aria-describedby="inputGroupPrepend"
                  name="username"
                  value={values.EmailId}
                  onChange={handleChange}
                  isInvalid={!!errors.EmailId}
                />
                <Form.Control.Feedback type="invalid" tooltip>
                  {errors.EmailId}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group
              as={Col}
              md="6"
              controlId="validationFormik103"
              className="position-relative"
            >
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
               /*  placeholder="Phone Number" */
                name="PhoneNumber"
                value={values.PhoneNumber}
                onChange={handleChange}
                isInvalid={!!errors.city}
              />

              <Form.Control.Feedback type="invalid" tooltip>
                {errors.city}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              as={Col}
              md="6"
              controlId="validationFormik104"
              className="position-relative"
            >
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                /* placeholder="State" */
                name="state"
                value={values.State}
                onChange={handleChange}
                isInvalid={!!errors.State}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.State}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              as={Col}
              md="3"
              controlId="validationFormik105"
              className="position-relative"
            >
   
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.zip}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Form.Group className="position-relative mb-3">
            <Form.Label>Comment</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              required
              name="comment"
              onChange={handleChange}
              isInvalid={!!errors.comment}
            />
            <Form.Control.Feedback type="invalid" tooltip>
              {errors.file}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="position-relative mb-3">
            <Form.Check
              required
              name="terms"
              label="Agree to terms and conditions"
              onChange={handleChange}
              isInvalid={!!errors.terms}
              feedback={errors.terms}
              feedbackType="invalid"
              id="validationFormik106"
              feedbackTooltip
            />
          </Form.Group>
          <Button type="submit">Submit form</Button>
        </Form>
      )}
    </Formik>
    </>
  );
}

export default ContactForm;