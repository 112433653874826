import React from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import { Typography } from '@mui/material';

import ApartmentIcon from '@mui/icons-material/Apartment';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ScienceIcon from '@mui/icons-material/Science';
import AssignmentIcon from '@mui/icons-material/Assignment';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

 const BasicGrid=()=> {
  return (
    <Container style={
      {
        margin:'20px 0px'
      }
    }>
      
      <Typography gutterBottom variant="h5" component="div">
      Primary Highlights
        </Typography>
        <Typography variant="body2" color="text.secondary">
        Explore diverse training approaches—online, offline, weekend, and corporate—tailored to various needs. From virtual classes to hands-on sessions, these methods empower learners with flexible and interactive skill development opportunities.
        </Typography>


    <Box sx={{ flexGrow: 1 }}>

      <Grid container spacing={2}>

        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item>
          <Typography gutterBottom variant="h5" component="div">
            
          <ApartmentIcon className='featureicon'/>
       
          100% Job Oriented Training
        </Typography>
        <Typography variant="body2" color="text.secondary">
        "ITproSkillSet offers industry-aligned training ensuring job readiness. Gain practical skills, hands-on experience, and specialized knowledge for high-demand IT roles. Our mission: empowering your career with 100% job-focused training."
        </Typography>
          </Item>
        </Grid>
        
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item>
          <Typography gutterBottom variant="h5" component="div">
            <SupervisorAccountIcon className='featureicon' />
          Export Guidance
        </Typography>
        <Typography variant="body2" color="text.secondary">
        "ITproSkillSet Export Guidance empowers you with essential knowledge for global trade. Our expertise ensures seamless navigation of export regulations, market insights, and strategic planning, fostering international business success."
        </Typography>
          </Item>
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item>
          <Typography gutterBottom variant="h5" component="div">
            <ScienceIcon className='featureicon' />
          Practical application approach
        </Typography>
        <Typography variant="body2" color="text.secondary">
        "ITproSkillSet" offers hands-on training, real-world scenarios, immersive labs, industry simulations, job-specific tasks, practical exercises, experiential learning, applied IT skills, task-oriented workshops, active learning modules,job-ready preparation.
        </Typography>
          </Item>
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item>
          <Typography gutterBottom variant="h5" component="div">
            <AssignmentIcon className='featureicon' />
          Integrated syllabus
        </Typography>
        <Typography variant="body2" color="text.secondary">
        The ITproSkillSet curriculum seamlessly combines tech expertise with essential skills, fostering holistic development. It merges technical know-how with communication, teamwork, and problem-solving abilities.
        </Typography>
          </Item>
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item>
          <Typography gutterBottom variant="h5" component="div">
            <WorkspacePremiumIcon className='featureicon' />
          Certificate on Completion
        </Typography>
        <Typography variant="body2" color="text.secondary">
        Upon fulfilling the course requirements, you'll receive a Certificate of Completion in ITproSkillSet, validating your proficiency in essential IT skills and enhancing your professional portfolio.
        </Typography>
          </Item>
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Item>
          <Typography gutterBottom variant="h5" component="div">
            <OndemandVideoIcon className='featureicon' />
          Heigh Quality Videos
        </Typography>
        <Typography variant="body2" color="text.secondary">
        "ITproSkillSet delivers high-quality videos, ensuring superior visual content for comprehensive learning experiences. Elevate your skills with our top-notch video productions, setting new standards in online education."
        </Typography>
          </Item>
        </Grid>


        
      </Grid>
    </Box>
    </Container>);
} 
export default BasicGrid

