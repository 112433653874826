import React from 'react';

function UiUxCssCourseContent() {
  // Creating an array of headings
  const headings = [
    {
      title: "CSS3 Basics",
      subheadings: ['Overview of Cascading Style Sheets3 (CSS3).',
        'Understanding the syntax used in CSS3.',
        'Overview of various CSS3 selector types.',
        'Working with colors in CSS3.',
        'Applying background styles in CSS3.',
        'Styling borders with CSS3.',
        'margins and padding in CSS3.',
        'Understanding the CSS3 box model.'
        
             ]
    },
    {
      title: "CSS3 Layout",
      subheadings: ['Controlling the display of elements',
        'Positioning elements in CSS3.' ,
       ' Creating grid-based layouts with CSS3.'
        
        ]
    },
    {
      title: "CSS3 Transitions",
      subheadings: ['Creating animations with CSS3.',
      'Applying transformations to elements in CSS3.',
      'Working with both 2D and3D transformations'      
        ]
    },
    {
      title: "CSS3 Typography",
      subheadings: ['Controlling text properties in CSS3.',
       'Handling text overflow in CSS3.'
      
        ]
    },
    {
      title: "CSS3 Advanced",
      subheadings: ['variables in CSS3 for efficient',
       'Creating multiple columns with CSS3.'
        
        ]
    },
    {
      title: "CSS3 Flexibility",
      subheadings: ['Creating responsive tables with CSS3.',
       
       'Designing responsive forms with CSS3.'
       
        ]
    },
    {
      title: "CSS3 Transform",
      subheadings: ['Applying 2D transformations to elements.',
        'Introducing 3D transformations in CSS3.',
       ' Transitions.'
     
        
        
        ]
    },
    {
      title: "CSS3 Gradients",
      subheadings: ['Creating linear gradients in CSS3.', 
        'Implementing radial gradients with CSS3.',
       ' color stops in CSS3 gradients.' 
        ]
    },
    {
      title: "CSS3 Advanced Pseudo",
      subheadings: ['Pseudo-elements ', 'Using pseudo-classes' 
        ]
    },
    {
      title: "CSS3 Flexbox",
      subheadings: ['Understanding the basics of Flexbox', 
        'flex container and layout properties in CSS3. '
         
        
        ]
    },
    {
      title: "CSS3 Grid",
      subheadings: ['Understanding the basics of CSS Grid.',
        'Containers Grid'
       
        ]
    },
    {
      title: "CSS3 Responsive",
      subheadings: ['Introduction to responsive web ','Making images and videos,Table responsive '
        ]
    }


  ];

  return (
    <div>
      
      {headings.map((heading, index) => (
        <div key={index}>
          <h2 style={{textAlign:'left'}}>{heading.title}</h2>
          <ul>
            {heading.subheadings.map((subheading, subIndex) => (
              <li key={subIndex} style={{textAlign:'left'}}>{subheading}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

export default UiUxCssCourseContent;
