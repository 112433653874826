import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import ContactForm from './ContactForm';
import { CardMedia, Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import contactimage from '../images/courses/contact.svg';



 const Contactmore=() =>{

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  return (
    <Container>
      


      <Typography gutterBottom variant="h5" component="div">
        Contact For More Infro..
        </Typography>

    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
 

        <Grid xs={12} sm={12} md={4} lg={4}>
        
          <Typography gutterBottom variant="h5" component="div">
        Email
        </Typography>

        <Typography variant="body2" color="text.secondary">
        info@jeevanit.com<br></br>
        jeevanitinfo@gmail.com
        </Typography>
       


        </Grid>

        <Grid xs={12} sm={12} md={4} lg={4}>

          <Typography gutterBottom variant="h5" component="div">
        Address
        </Typography>

        <Typography variant="body2" color="text.secondary">
        Near Dilsukhnagar Metro Station, <br></br>Opp. Konark Theater,Dilsukhnagar, Hyderabad.
        </Typography>
  

        </Grid>

        <Grid xs={12} sm={12} md={4} lg={4}>

          <Typography gutterBottom variant="h5" component="div">
        Phone & Whatsapp
        </Typography>

        <Typography variant="body2" color="text.secondary">
        +91 9640 30 9831,<br></br>
        +91 9493 10 8933
        </Typography>
        


        </Grid>


      </Grid>
    </Box>
    </Container>
  );
}

export default Contactmore

